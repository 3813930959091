/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import ReflexrVideo from '../Videos/ReflexrVideo';

// core components

function LandingPageHeaderReflexr() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/img/reflexr_logo.png") + ")"
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
        
      >
        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h1></h1>
            <h3></h3>
            
            <ReflexrVideo></ReflexrVideo>

          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeaderReflexr;
