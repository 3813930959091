/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionTerms() {
  return (
    <>
      <Container className="tim-container">
        <div className="title">
          <h3></h3>
        </div>
        <div id="typography">
          <Row>
            <div className="typography-line">
            <h2>Super Bounce Games, LLC Terms of Service</h2>
              <h3>1. Terms</h3>
              <p>By accessing our app, Reflexr Reloaded, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing Reflexr Reloaded. The materials contained in Reflexr Reloaded are protected by applicable copyright and trademark law.</p>
              <h3>2. Use License</h3>
              <ol type="a">
                <li>Permission is granted to temporarily download one copy of Reflexr Reloaded per device for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                <ol type="i">
                    <li>modify or copy the materials;</li>
                    <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                    <li>attempt to decompile or reverse engineer any software contained in Reflexr Reloaded;</li>
                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                    <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                </ol>
                  </li>
                <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Super Bounce Games, LLC at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
              </ol>
              <h3>3. Disclaimer</h3>
              <ol type="a">
                <li>The materials within Reflexr Reloaded are provided on an 'as is' basis. Super Bounce Games, LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
                <li>Further, Super Bounce Games, LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to Reflexr Reloaded.</li>
              </ol>
              <h3>4. Limitations</h3>
              <p>In no event shall Super Bounce Games, LLC or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use Reflexr Reloaded, even if Super Bounce Games, LLC or a Super Bounce Games, LLC authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
              <h3>5. Accuracy of materials</h3>
              <p>The materials appearing in Reflexr Reloaded could include technical, typographical, or photographic errors. Super Bounce Games, LLC does not warrant that any of the materials on Reflexr Reloaded are accurate, complete or current. Super Bounce Games, LLC may make changes to the materials contained in Reflexr Reloaded at any time without notice. However Super Bounce Games, LLC does not make any commitment to update the materials.</p>
              <h3>6. Links</h3>
              <p>Super Bounce Games, LLC has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Super Bounce Games, LLC of the site. Use of any such linked website is at the user's own risk.</p>
              <h3>7. Modifications</h3>
              <p>Super Bounce Games, LLC may revise these terms of service for its app at any time without notice. By using Reflexr Reloaded you are agreeing to be bound by the then current version of these terms of service.</p>
              <h3>8. Governing Law</h3>
              <p>These terms and conditions are governed by and construed in accordance with the laws of California and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
            </div>
          </Row>
        </div>
        <br />
        <div id="images">
          <Container>
            {/* <div className="title">
              <h3>Developers... yeah our names are similar</h3>
            </div> */}
            <Row>
              {/* <Col md="3" sm="6">
                <h4 className="images-title">Rounded Image</h4>
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/uriel-soberanes.jpg")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/faces/joe-gardner-2.jpg")}
                    />
                  </div>
                  <p>Sonia Green</p>
                </div>
              </Col> */}
              {/* <Col className="mr-auto ml-auto" md="2" sm="3">
                <h4 className="images-title">Dev 1</h4>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/kaci-baum-2.jpg")}
                />
                <p className="text-center">Ian Winter</p>
              </Col>
              <Col className="mr-auto ml-auto" md="2" sm="3">
                <h4 className="images-title">Dev 2</h4>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/kaci-baum-2.jpg")}
                />
                <p className="text-center">Ian Hunter</p>
              </Col> */}
              {/* <Col className="mr-auto ml-auto" md="2" sm="3">
                <h4 className="images-title">Circle Image</h4>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/kaci-baum-2.jpg")}
                />
                <p className="text-center">Brigitte Bardot</p>
              </Col>
              <Col className="mr-auto" md="2" sm="3">
                <h4 className="images-title">Thumbnail</h4>
                <img
                  alt="..."
                  className="img-thumbnail img-responsive"
                  src={require("assets/img/faces/erik-lucatero-2.jpg")}
                />
                <p className="text-center">John Keynes</p>
              </Col> */}
            </Row>
          </Container>
          <br />
        </div>
      </Container>
    </>
  );
}

export default SectionTerms;
