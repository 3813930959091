/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionTerms() {
  return (
    <>
      <Container className="tim-container">
        <div className="title">
          <h3></h3>
        </div>
        <div id="typography">
          <Row>
            <div className="typography-line">
            <h2>Privacy Policy</h2>
              <h2> </h2>
              <p><strong>Your privacy is important to us. It is Super Bounce Games, LLC's policy to respect your privacy regarding any information we may collect from you through our apps or games.</strong></p>
              <p><strong>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</strong></p>
              <p><strong>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</strong></p>
              <p><strong>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</strong></p>
              <p><strong>Our apps or games may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</strong></p>
              <p><strong>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</strong></p>
              <p><strong>Your continued use of our app will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</strong></p>
              <p><strong>This policy is effective as of 1 July 2020.</strong></p>
            </div>
          </Row>
        </div>
        <br />
        <div id="images">
          <Container>
            {/* <div className="title">
              <h3>Developers... yeah our names are similar</h3>
            </div> */}
            <Row>
              {/* <Col md="3" sm="6">
                <h4 className="images-title">Rounded Image</h4>
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/uriel-soberanes.jpg")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/faces/joe-gardner-2.jpg")}
                    />
                  </div>
                  <p>Sonia Green</p>
                </div>
              </Col> */}
              {/* <Col className="mr-auto ml-auto" md="2" sm="3">
                <h4 className="images-title">Dev 1</h4>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/kaci-baum-2.jpg")}
                />
                <p className="text-center">Ian Winter</p>
              </Col>
              <Col className="mr-auto ml-auto" md="2" sm="3">
                <h4 className="images-title">Dev 2</h4>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/kaci-baum-2.jpg")}
                />
                <p className="text-center">Ian Hunter</p>
              </Col> */}
              {/* <Col className="mr-auto ml-auto" md="2" sm="3">
                <h4 className="images-title">Circle Image</h4>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/kaci-baum-2.jpg")}
                />
                <p className="text-center">Brigitte Bardot</p>
              </Col>
              <Col className="mr-auto" md="2" sm="3">
                <h4 className="images-title">Thumbnail</h4>
                <img
                  alt="..."
                  className="img-thumbnail img-responsive"
                  src={require("assets/img/faces/erik-lucatero-2.jpg")}
                />
                <p className="text-center">John Keynes</p>
              </Col> */}
            </Row>
          </Container>
          <br />
        </div>
      </Container>
    </>
  );
}

export default SectionTerms;
