/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {useSpring, animated} from 'react-spring'
import {Spring} from 'react-spring/renderprops'

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import range from 'lodash-es/range'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// core components

function SectionEmailSent() {
  return (
    <>
      <Container>
        <div className="title">
        </div>
        <div id="typography-line-center">
          <Row>
            <div className="typography-line-center" >
              <h1>
                {/* <span className="note">Header 1</span> */}
                <Spring 
                  from={{ opacity: 0, }}
                  to={{ opacity: 1 }}>
                  {props => <div style={props}>An email has been sent!<br /></div>}
                </Spring>
                <br></br>
                <small>Check your email.</small>
                <br></br>

                <br></br>
              </h1>
            </div>
          </Row>
          <Container fluid >
                <Col>
                <Component3 />
                </Col>
                <br></br>
                <br></br>
                <div className="trampoline_only"> </div>
                {/* <Trampoline /> */}
                
          </Container>
          <Row>
          <div className="typography-line-center" >
              <h4>
                {/* <span className="note">Header 1</span> */}
                <br></br>
                <Spring 
                  from={{ opacity: 0, }}
                  to={{ opacity: 0.8 }}>
                  {props => <div style={props}>Click here to resend an email<br /></div>}
                </Spring>
              </h4>
            </div>
          </Row>
        </div>
        <br />
        <div id="images">
          <Container>
            <Row>
            
            </Row>
          </Container>
          <br />
        </div>
      </Container>
    </>
  );
}

const items = range(1)
const interp = i => r => `translate3d(0, ${70 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`

function Component3() {
  //const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: '2000' });
  const { radians } = useSpring({
    to: async next => {
      while (1) await next({ radians: 4 * Math.PI })
    },
    from: { radians: 0 },
    config: { duration: 3000 },
    reset: true,
  })
  return items.map(i => <animated.div key={i} className="script-bf-box-email" style={{ transform: radians.interpolate(interp(i)) }} />)
  //return <animated.div style={props}><h1>I will fade in</h1></animated.div>
}

// function Trampoline() {
//   //const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: '2000' });
//   const { opacity } = useSpring({
//     from: { opacity: 0 },
//     to: { opacity: 1 },
//     config: { duration: 500 },
//     reset: true,
//   })
//   return items.map(i => <animated.div key={i} className="trampoline_only" style={{ opacity: opacity }} />)
//   //return <animated.div style={props}><h1>I will fade in</h1></animated.div>
// }


export default SectionEmailSent;
