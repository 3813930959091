/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
// import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ExamplesNavbar from "components/Navbars/IndexNavbar.js";
// import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import LandingPageHeaderReflexr from "components/Headers/LandingPageHeaderReflexr.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import DemoFooterGoogleApple from "components/Footers/DemoFooterGoogleApple.js";


const styles= {
  parent: {
    margin: "auto",
    display: "flex",
    height: "80%",
    width: "80%"
  },
  child: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
};

function ReflexrReloaded() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      
      <ExamplesNavbar />
      <LandingPageHeaderReflexr />
      <div className="main">
        
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">REFLEXR RELOADED</h2>
            <h3 className="title">The Ultimate Reflex Challenge</h3>
            {/* <Row> */}
            {/* <Col className="ml-auto mr-auto text-center" md="6"> */}
            <br/>
                       
              <div className="parent">
           
              <a href='https://apple.co/3hsx2oZ' className="child"><img alt='Download on the App Store' src='https://www.designpieces.com/wp-content/uploads/2016/02/download-on-the-app-store.png' style={{ width: "34.5%", marginLeft:"auto", marginRight:"auto",display:"table-cell", textAlign:"center",verticalAlign:"center" }}/></a>
       
             <br></br>
       {/* <Row><a href='https://apple.co/3hsx2oZ'><img alt='Download on the App Store' src={require("assets/img/apple-store-badge.svg")} style={{ width: "32%" }}/></a> </Row> */}
       
       <a href='https://play.google.com/store/apps/details?id=com.SuperBounceGames.ReflexrReloaded&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' className="child">
             <img alt='Get it on Google Play' src={require("assets/img/google-play-badge.png")} style={{ width: "40%", marginLeft:"auto", marginRight:"auto",display:"table-cell", textAlign:"center",verticalAlign:"center" }}/></a>
           
           </div>
          </Container>
        </div>
        
      </div>
      <DemoFooterGoogleApple />
    </>
  );
}

export default ReflexrReloaded;
