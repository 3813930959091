/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button,Container,Col,Row } from "reactstrap";

// core components
import DemoFooter from "components/Footers/DemoFooter.js";

function IndexHeaderReflexr() {
  return (
    <>
      <div
        
      >

      </div>
    </>
  );
}


export default IndexHeaderReflexr;


// /*!

// =========================================================
// * Paper Kit React - v1.0.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/paper-kit-react

// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
// /*eslint-disable*/
// import React from "react";

// // reactstrap components
// import { Button,Container,Col,Row } from "reactstrap";

// // core components
// import DemoFooter from "components/Footers/DemoFooter.js";

// function IndexHeaderReflexr() {
//   return (
//     <>
//       <div
//         className="page-header"
//         style={{
//           backgroundImage:
//             "url(" + require("assets/img/reflexr_logo.png") + ")"
//         }}
//       >
//         <div className="filter" />
//         <div className="content-center">
//           <Container>
//             <div className="title-brand">
//               {/* <img alt="..." src={require("assets/img/logo_transparent.png")} style={{ width: '70%' }}   /> 
//               {/* <h1 className="presentation-title">Wild West Showdown</h1> */}
//               {/* <div className="fog-low">
//                 <img alt="..." src={require("assets/img/fog-low.png")} style={{filter: 'sepia'}} />
//               </div> */}
//               {/* <div className="fog-low right">
//                 <img alt="..." src={require("assets/img/fog-low.png")} />
//               </div> */}
//             </div>
//             {/* <h2 className="presentation-subtitle text-center" style={{padding: '40px'}}>
//               Are you the best in the West?
//             </h2> */}
//           </Container>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>
//           <br/>

//           <Row>
//             {/* <Col className="ml-auto mr-auto text-center" md="6"> */}
//             <br/>
//                <a href='https://play.google.com/store/apps/details?id=com.SuperBounceGames.ReflexrReloaded'><img alt='Get it on Google Play' src='https://www.designpieces.com/wp-content/uploads/2016/02/google-play-badge-300x89.png' /></a>              
              
//                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//               <a href='https://apple.co/3hsx2oZ'><img alt='Download on the App Store' src='https://www.designpieces.com/wp-content/uploads/2016/02/download-on-the-app-store.png'/></a>             
//           </Row>
          
//         </div>
//         <div
//           className="moving-clouds"
//           style={{
//             backgroundImage: "url(" + require("assets/img/pink_clouds.png") + ")"
//           }}
//         />
//         {/* <h6 className="category category-absolute">
//           Can you handle the West?{" "}
//           <a
//             href="https://www.creative-tim.com?ref=pkr-index-page"
//             target="_blank"
//           >
//             <img
//               alt="..."
//               className="creative-tim-logo"
//               src={require("assets/img/creative-tim-white-slim2.png")}
//             />
//           </a>
//         </h6> */}
//       </div>
//       <div>
//       <DemoFooter />

//       </div>
//     </>
//   );
// }

// export default IndexHeaderReflexr;
