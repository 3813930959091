/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'
library.add(fab, faCheckSquare, faCoffee)

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooterGoogleApple() {
  return (
    <footer className="footer footer-black footer-white auto">
      <Container>
        <Row className="copyright" style={{flex:1,justifyContent: "center",alignItems: "center"}}>
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" />   by Super Bounce Games LLC
              </Row>
              <Row className="copyright" style={{flex:1,justifyContent: "center",alignItems: "center"}}>
          App Store and the App Store logo are trademarks of Apple Inc Inc.
            </Row>
          <Row className="copyright" style={{flex:1,justifyContent: "center",alignItems: "center"}}>
            Google Play and the Google Play logo are trademarks of Google LLC
              </Row>
          
      </Container>
    </footer>
  );
}

export default DemoFooterGoogleApple;
