/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import {useSpring, animated} from 'react-spring'
import {Spring} from 'react-spring/renderprops'
import { Redirect } from 'react-router'

import * as qs from 'query-string';

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import range from 'lodash-es/range'


// core components
var data = ""; 
var json = "";

function SectionEmailCheckVerification() {
  return (
    <>
      <Container>
        <div className="title">
        </div>
        <div id="typography-line-center">
          <Row>
            <div className="typography-line-center" >
              <h1>
                {/* <span className="note">Header 1</span> */}
                <Spring 
                  from={{ opacity: 0, }}
                  to={{ opacity: 1 }}>
                  {props => <div style={props}>Checking Email Verification<br /></div>}
                </Spring>
                <br></br>
                <small>Waiting... </small>
                <Dashboard/>
                <br></br>
                <br></br>
              </h1>
            </div>
          </Row>
          <Container fluid >
                <Col>
                <Component3 />
                </Col>
                <div className="trampoline_only"> </div>
                {/* <Trampoline /> */}
          </Container>
        </div>
        <br />
        <div id="images">
          <Container>
            <Row>
            
            </Row>
          </Container>
          <br />
        </div>
      </Container>
    </>
  );
}



const items = range(1)
const interp = i => r => `translate3d(0, ${70 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`

const Dashboard = props => {
  const parsed = qs.parse(window.location.search);
  console.log("DASHBOARD")
          useEffect(() => {
          async function sendToApiServer(parsed) {
            if(parsed != undefined) 
            {
              var token = parsed.token
                const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({token: token})
              };
              const response = await fetch('https://spring.games/confirmation', requestOptions);
              console.log(response);
              json = await response.json();
              console.log(json);
              console.log(json.msg);
              // if(json.msg = "Congrats! Your email has been verified! Welcome to Super Bounce Games!")
              // {
              //   return <Redirect to='/account/email/verified' />
              // }
              // else{
              //   return <Redirect to='/' />
              // }
            }
            return json.msg;
              
          }
          sendToApiServer(parsed);
          }, [])
          //var message = await getApiValues(parsed.token);
        //   console.log("Huzzah!");
        //   console.log("data:",  data);
          setTimeout(
            function() {
              var value = checkJson()
              if(value == "Error")
              {
                // return <Redirect to='/' />
                console.log(value)
              }
              else{
                // <Redirect to='/account/email/verified' />
                console.log(value)
              }
              
            }
            .bind(this),
            5000
        );
        
          // console.log("json", json);
      return <div> </div>
}



// async function sendToApiServer(parsed) {
//   if(parsed != undefined) 
//   {
//     var token = parsed.token
//       const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({token: token})
//     };
//     const response = await fetch('https://spring.games/confirmation', requestOptions);
//     console.log(response);
//     json = await response.json();
//     console.log(json);
//     console.log(json.msg);
//     if(json.msg = "Congrats! Your email has been verified! Welcome to Super Bounce Games!")
//     {
//       return <Redirect to='/account/email/verified' />
//     }
//     else{
//       return <Redirect to='/' />
//     }
    
//     await timeout(1000); //for 1 sec delay
//     return json;
//   }
//   return null;
    
// }

function timeout(delay: number) {
  return new Promise( res => setTimeout(res, delay) );}

function checkJson() {
    //const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: '2000' });
    console.log(json.msg);
    // return json.msg;
    if(json.msg == "Congrats! Your email has been verified! Welcome to Super Bounce Games!")
    {
      window.location.href = "https://superbounce.games/account/email/verified";
      // return <Redirect to='/' />
    }
    else{
      window.location.href = "https://superbounce.games/account/email/resend";
    }
    //return <animated.div style={props}><h1>I will fade in</h1></animated.div>
}

function Component3() {
  //const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: '2000' });
  const { radians } = useSpring({
    to: async next => {
      while (1) await next({ radians: 4 * Math.PI })
    },
    from: { radians: 0 },
    config: { duration: 3000 },
    reset: true,
  })
  return items.map(i => <animated.div key={i} className="script-bf-box" style={{ transform: radians.interpolate(interp(i)) }} />)
  //return <animated.div style={props}><h1>I will fade in</h1></animated.div>
}

// function Trampoline() {
//   //const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: '2000' });
//   const { opacity } = useSpring({
//     from: { opacity: 0 },
//     to: { opacity: 1 },
//     config: { duration: 500 },
//     reset: true,
//   })
//   return items.map(i => <animated.div key={i} className="trampoline_only" style={{ opacity: opacity }} />)
//   //return <animated.div style={props}><h1>I will fade in</h1></animated.div>
// }


export default SectionEmailCheckVerification;
