/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import AboutPage from "views/examples/AboutPage.js";
import TermsPage from "views/examples/TermsPage.js";
import ContactPage from "views/examples/ContactPage.js";
import GamesPage from "views/examples/GamesPage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import PrivacyPolicyPage from "views/examples/PrivacyPolicyPage.js";
import ResetPasswordPage from "views/examples/ResetPasswordPage";
import ResendEmailVerification from "views/examples/ResendEmailVerification";
import EmailVerified from "views/examples/EmailVerifiedPage";
import EmailSent from "views/examples/EmailSentPage";
import PasswordChangePage from "views/examples/PasswordChangePage";
import PasswordResetVerifiedPage from "views/examples/PasswordResetVerifiedPage";
import EmailCheckVerificationPage from "views/examples/EmailCheckVerificationPage";
import ReflexrReloaded from "views/examples/ReflexrReloaded.js";
import ContestPage from "views/examples/ContestPage.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route
        path="/nucleo-icons"
        render={props => <NucleoIcons {...props} />}
      /> */}
      {/* <Route
        path="/landing-page"
        render={props => <ReflexrReloaded {...props} />}
      /> */}
      {/* <Route
        path="/profile-page"
        render={props => <ProfilePage {...props} />}
      /> */}
      <Route
        path="/about"
        render={props => <AboutPage {...props} />}
      />
      <Route
        path="/terms"
        render={props => <TermsPage {...props} />}
      />
       <Route
        path="/privacy-policy"
        render={props => <PrivacyPolicyPage {...props} />}
      />
      <Route
        path="/contact"
        render={props => <ContactPage {...props} />}
      />
      <Route
        path="/contest"
        render={props => <ContestPage {...props} />}
      />
      <Route
        path="/games/ReflexrReloaded"
        render={props => <ReflexrReloaded {...props} />}
      />
      <Route
        path="/games"
        render={props => <GamesPage {...props} />}
      />
      {/* <Route
        path="/ReflexrReloaded"
        render={props => <ReflexrReloaded {...props} />}
      /> */}
      <Route
        path="/account/password/reset"
        render={props => <ResetPasswordPage {...props} />}
      />
      <Route
        path="/account/password/change"
        render={props => <PasswordChangePage {...props} />}
      />
      <Route
        path="/account/password/changed"
        render={props => <PasswordResetVerifiedPage {...props} />}
      />
      <Route
        path="/account/email/sent"
        render={props => <EmailSent {...props} />}
      />
      <Route
        path="/account/email/resend"
        render={props => <ResendEmailVerification {...props} />}
      />
      <Route
        path="/account/email/verified"
        render={props => <EmailVerified {...props} />}
      />
      <Route
        path="/account/email/checking"
        render={props => <EmailCheckVerificationPage {...props} />}
      />
      {/* <Route
        path="/register-page"
        render={props => <RegisterPage {...props} />}
      /> */}
      {/* HAVE THE REDIRECT BELOW EVERY OTHER PAGE, OTHERWISE REDIRECT to / DOESNT WORK */}
      <Route path="/" render={props => <Index {...props} />} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  </BrowserRouter>,
 

  document.getElementById("root")
);
