import React from 'react';
import YouTube from 'react-youtube';
 
class ReflexrVideo extends React.Component {
  render() {
    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };
 
    // return <YouTube class="video-container" videoId="yzgLIOrlpe8"  onReady={this._onReady} />;
    return <div
    className="video"
    style={{
      position: "relative",
      paddingBottom: "56.25%" /* 16:9 */,
      paddingTop: 25,
      height: 0
    }}
  >
    <iframe
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
      }}
      src={`https://www.youtube.com/embed/yzgLIOrlpe8`}
      frameBorder="0"
    />
  </div>

  }
 
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}
export default ReflexrVideo;