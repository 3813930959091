/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function AboutPage() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <IndexNavbar />
      <br />
      <br />
      <div className="section profile-content">
      <Container className="tim-container">
        <div className="title">
        <br />
          <h3>About us</h3>
        </div>
        <div id="typography">
          <Row>
            <div className="typography-line">
              <h2>
                {/* <span className="note">Header 1</span> */}
                Our Story:
                <p />
              </h2>
              <br />
              <h5>
                  Super Bounce Games was founded in 2020 by two lifelong 
                  friends with one goal: <p /> design and develop innovative gaming experiences.
                  
              </h5>
              <br />
              <h5>
                  Our debut title Wild West Showdown, a PC/VR dueling game, is currently in development.
              </h5>
            </div>
            
            <div className="typography-line">
              <h2>
                Behind the Name: <br />
              </h2>
              <br />
              <h5>
                Gaming has always been a huge part of our lives, and we spent countless hours playing the Halo series, 
                discovering and practicing super bounces. 
                We wanted a name that evoked that childhood love of gaming and paid homage to a life changing series.
              </h5>
              <br />
              <h5>
                
              If you'd like to know more about the history of super bouncing, check out this link:
              <a href="https://halo.fandom.com/wiki/Super_bouncing"> https://halo.fandom.com/wiki/Super_bouncing</a>
              </h5>
            </div>
          </Row>
        </div>
        <br />
        <div id="images">
          <Container>
            <Row>
            </Row>
          </Container>
          <br />
        </div>
      </Container>
          
      </div>
      <DemoFooter />
    </>
  );
}

export default AboutPage;
