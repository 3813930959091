/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useForm, useField } from "react-form";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionResendEmailVerification() {
  return (
    <>
      <Container className="tim-container">
        <div className="title">
          <h3>Super Bounce Games</h3>
        </div>
        <div id="typography">
          <Row>
            <div className="typography-line">
              <h1>
                {/* <span className="note">Header 1</span> */}
                Resend Email Verification Link<br />
                <small>
                  Enter in your email address
                  <br />
                  <br></br>
                  {MyForm()}
                </small>
              </h1>
            </div>
          </Row>
        </div>
        <br />
        <div id="images">
          <Container>
            <Row>
            </Row>
          </Container>
          <br />
        </div>
      </Container>
    </>
  );
}

function EmailField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps
  } = useField("email", {
    validate: validateEmail
  });

  return (
    <>
      <input {...getInputProps()} />{" "}
      {isValidating ? (
        <em>Validating...</em>
      ) : isTouched && error ? (
        <em>{error}</em>
      ) : null}
    </>
  );
}

var validator = require("email-validator");

async function validateEmail(email, instance) {
  if (!email) {
    return "A email is required";
  }

  if(!validator.validate(email))
  {
    return "Invalid email address";
  }

  return instance.debounce(async () => {
    console.log("checking email");
    await new Promise(resolve => setTimeout(resolve, 4000));
    // All names are valid, so return a false error
    return false;
  }, 500);
}

async function sendToApiServer(values) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: values.email })
  };
  const response = await fetch('https://spring.games/resend', requestOptions);
  const data = await response.json();
  // this.setState({ postId: data.id });
  return values;
}

function MyForm() {
  // Use the useForm hook to create a form instance
  var submission_response = ''
  const {
    Form,
    meta: { isSubmitting, canSubmit, onSubmit }
  } = useForm({
    onSubmit: async (values, instance) => {
      // onSubmit (and everything else in React Form)
      // has async support out-of-the-box
      await sendToApiServer(values);
      await new Promise(resolve => setTimeout(resolve, 3000));
      submission_response = 'Email Sent.'
      console.log("Huzzah!");
      window.location.href = "/account/email/sent";
    },
    debugForm: false
  });

  return (
    <Form>
      <div>
        <label>
          Email: <EmailField />
        </label>
      </div>
      <div>
        <button type="submit" disabled={!canSubmit}>
          Submit
        </button>
      </div>
      <label>
      </label>

      <div>
        <em>{isSubmitting ? "Submitting..." : null}</em>
        <em>{submission_response}</em>
      </div>
    </Form>
  );
}

export default SectionResendEmailVerification;
