import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

const styles= {
  parent: {
    margin: "auto",
    display: "flex",
    height: "80%",
    width: "80%"
  },
  child: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
};

// core components

// function SectionDarkReflexr() {
//   return (
//     <>
//       <div className="section section-dark">
//         <Container>
//           <Row>
//             <Col className="ml-auto mr-auto text-center" md="8">
//               <h2 className="title">Completed with examples</h2>
//               <img
//                   alt="..."
//                   className="img-rounded img-responsive"
//                   src={require("assets/img/reflexr_logo.png")}
//                   style={{ width: "100%" }}></img>
//               <p className="description">
//                 The kit comes with three pre-built pages to help you get started
//                 faster. You can change the text and images and you're good to
//                 go. More importantly, looking at them will give you a picture of
//                 what you can built with this powerful kit.
//               </p>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }

// export default SectionDarkReflexr;

function SectionDarkReflexr() {
  return (
    <>
      <div className="section section-dark" >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title"></h2>
              <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/reflexr_logo.png")}
                  style={{ width: "100%" }}></img>
              <p className="description">
                
              </p>
            </Col>
          </Row>
          <br></br>
          <h2 className="title" style={{ marginLeft:"auto", marginRight:"auto",textAlign:"center",verticalAlign:"center" }}>REFLEXR RELOADED</h2>
            <h3 className="title" style={{ marginLeft:"auto", marginRight:"auto",textAlign:"center",verticalAlign:"center" }}>The Ultimate Reflex Challenge</h3>
          <div className="parent">
           
              
          {/* <Row><a href='https://apple.co/3hsx2oZ'><img alt='Download on the App Store' src={require("assets/img/apple-store-badge.svg")} style={{ width: "32%" }}/></a> </Row> */}
          
          <a href='https://apple.co/3hsx2oZ' className="child"><img alt='Download on the App Store' src='https://www.designpieces.com/wp-content/uploads/2016/02/download-on-the-app-store.png' style={{ width: "34.5%", marginLeft:"auto", marginRight:"auto",display:"table-cell", textAlign:"center",verticalAlign:"center" }}/></a>
          
          <a href='https://play.google.com/store/apps/details?id=com.SuperBounceGames.ReflexrReloaded&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' className="child">
                <img alt='Get it on Google Play' src={require("assets/img/google-play-badge.png")} style={{ width: "40%", marginLeft:"auto", marginRight:"auto",display:"table-cell", textAlign:"center",verticalAlign:"center" }}/></a>
                <br></br>

          
          </div>
          
          
          <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/pink_clouds.png") + ")"
          }}
        />
        </Container>
        
      </div>
      
    </>
  );
}

export default SectionDarkReflexr;
