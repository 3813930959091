/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function SectionGames() {
  return (
    <>
      <div className="section section-dark">
        <Container>
          <Row className="example-page">
            <Col className="text-center" md="6">
              {/* <a href="examples/landing.html" target="_blank"> */}
              <a href="/games/ReflexrReloaded" >
                <img
                  alt="Reflexr Logo"
                  className="img-rounded img-responsive"
                  src={require("assets/img/reflexr_logo.png")}
                  style={{ width: "100%" }}
                />
              </a>
              <Button
                className="btn-outline-neutral btn-round"
                color="default"
                href="/games/ReflexrReloaded"
                target="_blank"
              >
                Reflexr Reloaded
              </Button>
            </Col>
            <Col className="text-center" md="6">
              {/* <a href="examples/profile.html" target="_blank"> */}
              <a href="/landing-page" >
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/WildWestShowdownLogo.png")}
                  style={{ width: "100%" }}
                />
              </a>
              <Button
                className="btn-outline-neutral btn-round"
                color="default"
                href=""
                target="_blank"
              >
                Wild West Showdown
              </Button>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionGames;
