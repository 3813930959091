/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {useSpring, animated} from 'react-spring'
import {Spring} from 'react-spring/renderprops'
import { useForm, useField } from "react-form";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faEye } from '@fortawesome/free-solid-svg-icons'



import * as qs from 'query-string';

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import range from 'lodash-es/range'

import { values } from "lodash-es";

// core components

function SectionPasswordChange() {
  return (
    <>
      <Container>
        <div className="title">
        </div>
        <div id="typography-line-center">
        <Row>
            <div className="typography-line">
              <h1>
                {/* <span className="note">Header 1</span> */}
                Password<br />
                <small>
                  Update your new password.
                  <br />
                  <small>
                    <label>Password Requirements</label>
                    <ul>
                      <li>At least 8 characters—the more characters, the better.</li>
                      {/* <li>At least 1 uppercase letter</li>
                      <li>At least 1 number </li>
                      <li>At least 1 special character, e.g.,! @ # ?</li> */}
                    </ul>
                  </small>
                  {MyForm()}
                </small>
              </h1>
            </div>
          </Row>
        </div>
        <br />
        <div id="images">
          <Container>
            <Row>
            
            </Row>
          </Container>
          <br />
        </div>
      </Container>
    </>
  );
}

// const items = range(1)
// const interp = i => r => `translate3d(0, ${70 * Math.sin(r + (i * 2 * Math.PI) / 1.6)}px, 0)`

// function Component3() {
//   //const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: '2000' });
//   const { radians } = useSpring({
//     to: async next => {
//       while (1) await next({ radians: 4 * Math.PI })
//     },
//     from: { radians: 0 },
//     config: { duration: 3000 },
//     reset: true,
//   })
//   return items.map(i => <animated.div key={i} className="script-bf-box" style={{ transform: radians.interpolate(interp(i)) }} />)
//   //return <animated.div style={props}><h1>I will fade in</h1></animated.div>
// }


var submitDisabled = true;
var tokenCheck = false;

function PasswordField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps
  } = useField("password", {
    validate: validatePassword
    
  });

  return (
    <>
      <input {...getInputProps()} />{""}
      {isValidating ? (
        <em>Validating...</em>
      ) : isTouched && error ? (
        <em>  {error}</em>
      ) : null}
    </>
  );
}

async function validatePassword(password, instance) {
  if (!password) {
    return "A new password is required";
  }
    const re = /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~=_+}{":;'?/>.<,`\-\|\[\]]{6,50}$/;
  if(password.length < 8)
  {
    return "Password must be 8 characters or more."
  }
  if(hasSpaces(password))
  {
    return "Password can't have spaces."
  }
  // else if(!hasNumber(password))
  // {
  //   return "Password must container at least one number."
  // }
  // else if(!hasCapitalLetter(password))
  // {
  //   return "Password must container at least one uppercase letter."
  // }
  // else if(!hasSpecialCharacters(password))
  // {
  //   return "Password must container at least one special character -!$%^&*()_+|~={}<>?,. "
  // }

  return instance.debounce(async () => {
    console.log("checking password");
    await new Promise(resolve => setTimeout(resolve, 1000));
    // All names are valid, so return a false error
    return false;
  }, 500);
}

function hasSpaces(myString) {
  return /[ ]/.test(myString);
}
function hasNumber(myString) {
  return /\d/.test(myString);
}
function hasCapitalLetter(myString) {
  return /[A-Z]+/.test(myString);
}
function hasSpecialCharacters(myString) {
  return /[-!$%^&*()_+|~={}\<>?,.]/.test(myString);
}

function ConfirmPasswordField() {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps
  } = useField("confirmPassword", {
    validate: confirmValidatePassword
  });

  return (
    <>
      <input {...getInputProps()} />{" "} 
      {isValidating ? (
        <em>Validating...</em>
      ) : isTouched && error ? (
        <em>{error}</em>
      ) : null}
    </>
  );
}

async function confirmValidatePassword(confirmPassword, instance) {
  submitDisabled = true;
  console.log(instance);
  console.log("values: ");
  console.log(instance.value);
  console.log(instance.form.values.password);
  if (instance.form.values.password != undefined && confirmPassword != instance.form.values.password)
  {
    return "Re-enter new password";
  }
  // || !hasNumber(instance.form.values.password) || !hasCapitalLetter(instance.form.values.password) || !hasSpecialCharacters(instance.form.values.password)
  if(instance.form.values.password != undefined && instance.form.values.password.length < 8 || hasSpaces(instance.form.values.password) )
  {
    return ""
  }
  
  
  return instance.debounce(async () => {
    console.log("checking password");
    submitDisabled = false;
    console.log(submitDisabled);
    
    await new Promise(resolve => setTimeout(resolve, 1000));
    // All names are valid, so return a false error
    return false;
  }, 500);
}

async function sendToApiServer(values,token) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: values.password, token: token})
  };
  const response = await fetch('https://spring.games/pwreset', requestOptions);
  const data = await response.json();
  // this.setState({ postId: data.id });
  return data;
}

async function sendToApiServerToken(token) {
  if(tokenCheck == false) tokenCheck = true;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token: token })
};
const response = await fetch('https://spring.games/exists', requestOptions);
const data = await response.json();
//console.log(response)
console.log(data)
// this.setState({ postId: data.id });
return data;
}

function MyForm() {
  // Use the useForm hook to create a form instance
  //console.log(window.location.search);
  //=> '?foo=bar'

  // const parsed = qs.parse(window.location.search);
  // if(parsed != undefined && !submitDisabled)
  // {
  //   var token = parsed.token;
  //   var tokenExists = sendToApiServerToken(token);
  //   console.log('before');
  //   setTimeout(function(){
  //       console.log('after');
  //   },500);
  //   console.log(tokenExists.status);
  // }
  const delay = ms => new Promise(res => setTimeout(res, ms));

  const {
    Form,
    meta: { isSubmitting, canSubmit }
  } = useForm({
    onSubmit: async (values, instance) => {
      // onSubmit (and everything else in React Form)
      // has async support out-of-the-box
      const parsed = qs.parse(window.location.search);
      if(parsed != undefined && !submitDisabled)
      {
          var reponsemsg = await sendToApiServer(values, parsed.token);
          //console.log(reponsemsg);
          if(reponsemsg.msg == "Congrats! Your password has been reset!")
          {
            await delay(5000)
            window.location.href = "/account/password/changed";
            // return <Redirect to='/' />
          }
          else{
            await delay(5000)
            window.location.href = "/account/email/resend";
          }
          console.log("Huzzah!");
      }
      
    },
    // debugForm: false
  });

  return (
    <Form>
      <div>
        <label>
          <small> New Password: <PasswordField /> </small>
          <br></br>
          <br></br>
          <small>Confirm Password: <ConfirmPasswordField /> </small>
        </label>
      </div>
      <br></br>
      <div>
        <button type="submit" disabled={submitDisabled}>
          Submit
        </button>
      </div>

      <div>
        <em>{isSubmitting ? "Submitting..." : null}</em>
      </div>
    </Form>
  );
}




export default SectionPasswordChange;
