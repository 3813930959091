/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionContest() {
  return (
    <>
      <Container className="tim-container">
        <div className="title">
          <h3></h3>
        </div>
        <div id="typography">
          <Row>
            <div className="typography-line">
            <h1>Pro Reflexr Challenge: Official Rules and Regulations</h1>
              <h2>Rules</h2>
              <h3>1. How To Enter</h3>
              <p> 1-a) Download the game here: <a href='/' className="child">Reflexr Reloaded </a> </p>
              <p> 1-b) A verified Super Bounce Games account is required to participate in the competition.</p>
              <p> 1-c) A working internet connection is required, offline or guest game results are not valid in the competition</p>
              <p> 1-d) Games played while satisfying the requirements of 3-a, 3-b, and all Regulations listed below are automatically entered into the competition</p>
              {/* TODO @IAN ADD THE DOWNLOAD LINKS */}
              <h3>2. Determining Winners</h3>
              <p> 2-a) Winners will be determined based on the Score Leaderboard in Reflexr Reloaded exactly at 8pm PST 10/11/2020</p>
              <p> 2-b) In the event of a tie, winners will be determined by comparing the tied players' Reflexes Leaderboard positions at 8pm PST 10/11/2020</p>
              <p> 2-c) Further ties follow the same procedure, comparing first Game Duration Leaderboard Positions and finally Longest Combo Leaderboard Positions</p>
              <h3>3. Time Cutoff</h3>
              <p> 3-a) Only game results received by the server before 8pm PST on 10/11/2020 will be valid for the competition</p>
              <h3>4. Awarding of Prizes</h3>
              <p> 4-a) Once winners have been determined, we will contact them through their Super Bounce Games account email within seven (7) days to coordinate payment</p>
              <p> 4-b) Failure to respond with the necessary information within seven (7) days of email receipt will result in forfeiture of the prize</p>
              <h3>5. Referrals</h3>
              <p> 5-a) You must have a verified Super Bounce Games account to refer someone</p>
              <p> 5-b) Their account must be created after yours to be a valid referral</p>
              <p> 5-c) You can refer unlimited people, however you can only be referred by one person</p>
              <h3>6. Alteration of Rules</h3>
              <p> 6-a) We (Super Bounce Games) reserve the right to alter the rules and regulations of this competition at any time</p>
              <h2>Regulations</h2>
              <h3>1. Eligibility to Compete</h3>
              <p> 1-a) Entrants must be at least 13 years old</p>
              <p> 1-b) Entrants must live in the United States (excluding residents of Rhode Island)</p>
              <h3>2. Disqualification</h3>
              <p> 2-a) Utilizing any cheats, hacks, or exploits is grounds for immediate disqualification from the competition</p>
              <p> 2-b) The aforementioned includes, but is not limited to: sending in altered results, playing on someone else's account, someone else playing on your account</p>
              <p> 2-c) We (Super Bounce Games) reserve the right to disqualify any entrant at any time for any reason at our discretion</p>
              <h2>Miscellaneous</h2>
              <p>1. Privacy</p>
              <p> 1-a) By entering the competition you consent to our Privacy Policy, viewable at https://superbounce.games/privacy-policy</p>
              <p>2. Legal</p>
              <p> 2-a) All aspects of this competition are Void where prohibited by law</p>
            </div>
          </Row>
        </div>
        <br />
      </Container>
    </>
  );
}

export default SectionContest;
